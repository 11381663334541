import React, { useState, useEffect, Suspense, lazy } from "react";
import { Switch, Redirect } from "react-router-dom";
import { Routes } from "./routes";
import axios from "./axios";
import "./App.css";
import { useLoadScript } from "@react-google-maps/api";
import { Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { locationSet, userDataSet } from "./redux/LogIn/loginSlice";
import { dispByStateSet } from "./redux/LogIn/homePageSlice";
import { loadingSet } from "./redux/loadingSlice";
import { fetchAdvertisementData } from "./redux/apiSlice";
import useCart from "./Hooks/UseCart";
import Preloader from "./components/Preloader";

// HOC
import RouteWithSidebar from "./HOCs/RouteWithSideBar";
import RouteWithLoader from "./HOCs/RouteWIthLoader";
import DispAdvertisementLeads from "./pages/dashboard/DispAdvertisementLeads";
import OmcLeads from "./pages/dashboard/OmcLeads";
import AllNotifications from "./pages/AllNotifications";
import Faq from "./pages/dashboard/Faq";
import FAQPage from "./pages/Website/Footer/FAQPage";
import BlogComments from "./pages/dashboard/BlogComments";
import EmailMarketing from "./pages/dashboard/EmailMarketing";

// Dynamic Imports for pages
const MyProfile = lazy(() => import("./pages/MyProfile"));
const Signin = lazy(() => import("./pages/Login/Signin"));
const Signup = lazy(() => import("./pages/Login/Signup"));
const ForgotPassword = lazy(() => import("./pages/Login/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/Login/ResetPassword"));
const NotFoundPage = lazy(() => import("./pages/Login/NotFound"));
const ServerError = lazy(() => import("./pages/Login/ServerError"));
const Home = lazy(() => import("./pages/Website/Home/Home"));
const DashboardOverview = lazy(() =>
  import("./pages/dashboard/DashboardOverview")
);
const States = lazy(() => import("./pages/dashboard/States"));
const DispensaryTypes = lazy(() => import("./pages/dashboard/DispensaryTypes"));
const Dispensaries = lazy(() => import("./pages/dashboard/Dispensaries"));
const ProductTypes = lazy(() => import("./pages/dashboard/ProductTypes"));
const Plans = lazy(() => import("./pages/dashboard/Plans"));
const Coupons = lazy(() => import("./pages/dashboard/Coupons"));
const Blogs = lazy(() => import("./pages/dashboard/Blogs"));
const BlogTypes = lazy(() => import("./pages/dashboard/BlogTypes"));
const Products = lazy(() => import("./pages/dashboard/Products"));
const Reviews = lazy(() => import("./pages/dashboard/Reviews"));
const Users = lazy(() => import("./pages/dashboard/Users"));
const Orders = lazy(() => import("./pages/dashboard/Orders"));
const Deals = lazy(() => import("./pages/dashboard/Deals"));
const DispAdvertisement = lazy(() =>
  import("./pages/dashboard/DispAdvertisement")
);
const PrivacyPolicy = lazy(() =>
  import("./pages/Website/Footer/PrivacyPolicy")
);
const Explore = lazy(() => import("./pages/Website/Explore"));
const NearbyDeals = lazy(() => import("./pages/Website/Deals/NearbyDeals"));
// const AllProducts = lazy(() => import("./pages/Website/Products/AllProducts"));
const ListComponent = lazy(() => import("./reusable/Components/ListComponent"));
const DispensaryDetails = lazy(() =>
  import("./pages/Website/Dispensary/DispensaryDetails")
);
const ProductDetails = lazy(() =>
  import("./pages/Website/Products/ProductDetails")
);
const BlogDetails = lazy(() => import("./pages/Website/Blogs/BlogsDetails"));
const UserCart = lazy(() => import("./pages/Website/UserCart"));
const UserCheckout = lazy(() => import("./pages/OrderCheckout"));
const OrderList = lazy(() => import("./pages/Website/OrdersList"));
const BuyPlan = lazy(() => import("./pages/Website/BuyPlan"));
const Payment = lazy(() => import("./pages/Website/BuyPlan/PaymentModal"));
const CustomerTermsOfUse = lazy(() =>
  import("./pages/Website/Footer/TermsOfUse/CustomerTermsOfUse")
);
const BusinessTermsOfUse = lazy(() =>
  import("./pages/Website/Footer/TermsOfUse/BusinessTermsOfUse")
);
const SupplementalAdsTerms = lazy(() =>
  import("./pages/Website/Footer/TermsOfUse/SupplemtalAdsTerms")
);
const SupplementalProductTerms = lazy(() =>
  import("./pages/Website/Footer/TermsOfUse/SupplementalProductTerms")
);
const AcceptableUsePolicy = lazy(() =>
  import("./pages/Website/Footer/TermsOfUse/AcceptableUsePolicy")
);
const DeliveryDataProcessingAddendum = lazy(() =>
  import("./pages/Website/Footer/TermsOfUse/DeliveryDataProcessingAddendum")
);
const ElectronicPolicy = lazy(() =>
  import("./pages/Website/Footer/TermsOfUse/ElectronicPolicy")
);
const OrderDataProcessingAddendum = lazy(() =>
  import("./pages/Website/Footer/TermsOfUse/OrdersDataProcessingAddendum")
);
const TermsOfUse = lazy(() => import("./pages/Website/Footer/TermsOfUse"));
const Brands = lazy(() => import("./pages/dashboard/Brands"));
const AllBrands = lazy(() => import("./pages/Website/Brands/AllBrands"));
const ClaimedLeads = lazy(() => import("./pages/dashboard/ClaimedLeads"));
const BillingList = lazy(() => import("./pages/dashboard/BillingList"));

// Maps Script loading
const libraries = ["places"];

const App = () => {
  // Redux state
  const user = useSelector((state) => state.login.userData);
  const { state, lat, long } = useSelector((state) => state.login.location);
  const { fetchCart } = useCart();
  const dispatch = useDispatch();
  const roleId = user.role_id;
  const planId = user.plan_id;
  const status = useSelector((state) => state.api.status);
  const error = useSelector((state) => state.api.error);

  // Loading state
  const [loading, setLoading] = useState(false);

  // useEffect hooks
  useEffect(() => {
    dispatch(loadingSet(loading));
  }, [loading, dispatch]);

  const authToken = localStorage.getItem("authToken");

  const fetchUserDetails = async () => {
    const config = {
      headers: { Authorization: `Bearer ${authToken}` },
    };
    if (authToken) {
      setLoading(true);
      fetchCart();
      try {
        const res = await axios.get(`/user/details/${user.id}`, config);
        dispatch(
          userDataSet({
            ...user,
            ...res.data.data.user,
            plan_id: res.data.data.plan_id,
            remaining_amount: res.data.data.remaining_amount,
          })
        );
      } catch (error) {
        console.error("Error fetching user details:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  const success = (pos) => {
    const crd = pos.coords;
    dispatch(locationSet({ lat: crd.latitude, long: crd.longitude }));
  };

  const errors = (err) => {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  };

  const getUserLatLong = () => {
    if (navigator.geolocation) {
      navigator.permissions.query({ name: "geolocation" }).then((result) => {
        if (result.state === "granted" || result.state === "prompt") {
          navigator.geolocation.getCurrentPosition(success, errors, options);
        }
      });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, [authToken]);

  useEffect(() => {
    getUserLatLong();
    getDispCountByState();
  }, []);

  useEffect(() => {
    if (status === "idle" && state?.long_name) {
      dispatch(fetchAdvertisementData({ state: state.long_name, lat, long }));
    }
    if (status === "failed") {
      alert(error);
    }
  }, [status, dispatch, state]);

  const getDispCountByState = async () => {
    try {
      const res = await axios("/dispensary/count/state");
      dispatch(dispByStateSet(res.data.data));
    } catch (err) {
      alert(err.response.data.message);
    }
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded || loading) {
    return (
      <Row className="justify-content-center align-item-center text-dark fw-bold p-4">
        <Preloader show={true} />
        Loading, Please wait...
      </Row>
    );
  }

  const admin = roleId === 1;
  const partner = roleId === 2 && planId;

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <RouteWithLoader exact path={Routes.Home.path} component={Home} />
        <RouteWithLoader
          exact
          path={Routes.termsOfUSe.path}
          component={TermsOfUse}
        />
        <RouteWithLoader
          path={Routes.customerTerms.path}
          component={CustomerTermsOfUse}
        />
        <RouteWithLoader
          exact
          path={Routes.businessTerms.path}
          component={BusinessTermsOfUse}
        />
        <RouteWithLoader
          exact
          path={Routes.supplementalAds.path}
          component={SupplementalAdsTerms}
        />
        <RouteWithLoader
          exact
          path={Routes.supplementalProduct.path}
          component={SupplementalProductTerms}
        />
        <RouteWithLoader
          exact
          path={Routes.acceptableUse.path}
          component={AcceptableUsePolicy}
        />
        <RouteWithLoader
          exact
          path={Routes.deliveryDataProcessingAddendum.path}
          component={DeliveryDataProcessingAddendum}
        />
        <RouteWithLoader
          exact
          path={Routes.electronicCommPolicy.path}
          component={ElectronicPolicy}
        />
        <RouteWithLoader
          exact
          path={Routes.ordersDataProcessingAddendum.path}
          component={OrderDataProcessingAddendum}
        />
        <RouteWithLoader
          exact
          path={Routes.PrivacyPolicy.path}
          component={PrivacyPolicy}
        />
        <RouteWithLoader exact path={Routes.faqPage.path} component={FAQPage} />
        <RouteWithLoader
          exact
          path={`${Routes.Explore.path}/:type/:state?/:filter?`}
          component={Explore}
        />
        <RouteWithLoader
          exact
          path={Routes.NearbyDeals.path}
          component={NearbyDeals}
        />
        <RouteWithLoader
          exact
          path={Routes.allBrands.path}
          component={AllBrands}
        />
        {/* <RouteWithLoader
          exact
          path={Routes.AllProducts.path}
          component={AllProducts}
        /> */}
        <RouteWithLoader
          exact
          path={`${Routes.ProductsByCategory.path}/:category/:id`}
          component={ListComponent}
        />
        <RouteWithLoader
          exact
          path={`${Routes.ProductsByBrand.path}/:brand/:id`}
          component={ListComponent}
        />
        <RouteWithLoader
          exact
          path={Routes.blogs.path}
          component={ListComponent}
        />
        <RouteWithLoader
          exact
          path={`${Routes.dispensaryDetails.path}/:id/:name/:type/:link_via_email?`}
          component={DispensaryDetails}
        />
        <RouteWithLoader
          exact
          path={`${Routes.productDetails.path}/:product/:product_id/:dis_id?`}
          component={ProductDetails}
        />
        <RouteWithLoader
          exact
          path={`${Routes.dealProducts.path}/:disp_name/:id`}
          component={ListComponent}
        />
        <RouteWithLoader
          exact
          path={`${Routes.blogDetails.path}/:id`}
          component={BlogDetails}
        />
        <RouteWithLoader exact path={Routes.cart.path} component={UserCart} />
        <RouteWithLoader
          exact
          path={Routes.userCheckout.path}
          component={UserCheckout}
        />
        <RouteWithLoader exact path={Routes.buyPlan.path} component={BuyPlan} />
        <RouteWithLoader
          path={`${Routes.planPayment.path}/:plan_id`}
          component={Payment}
        />
        <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
        <RouteWithLoader exact path={Routes.Signup.path} component={Signup} />
        <RouteWithLoader
          exact
          path={Routes.ForgotPassword.path}
          component={ForgotPassword}
        />
        <RouteWithLoader
          exact
          path={Routes.ResetPassword.path}
          component={ResetPassword}
        />
        <RouteWithLoader
          exact
          path={Routes.ChangePassword.path}
          component={ResetPassword}
          type="change-password"
        />
        <RouteWithLoader
          exact
          path={Routes.NotFound.path}
          component={NotFoundPage}
        />
        <RouteWithLoader
          exact
          path={Routes.ServerError.path}
          component={ServerError}
        />
        <RouteWithLoader
          exact
          path={Routes.allNotification.path}
          component={AllNotifications}
        />
        <RouteWithLoader
          exact
          path={Routes.MyProfile.path}
          component={MyProfile}
        />
        <RouteWithLoader
          exact
          path={Routes.myOrders.path}
          component={OrderList}
        />
        {(admin || partner) && (
          <RouteWithSidebar
            exact
            path={Routes.AdminDashboard.path}
            component={DashboardOverview}
          />
        )}
        {(admin || partner) && (
          <RouteWithSidebar
            exact
            path={Routes.States.path}
            component={States}
          />
        )}
        {admin && (
          <RouteWithSidebar
            exact
            path={Routes.DispensaryTypes.path}
            component={DispensaryTypes}
          />
        )}
        {(admin || partner) && (
          <RouteWithSidebar
            exact
            path={Routes.Dispensaries.path}
            component={Dispensaries}
          />
        )}
        {admin && (
          <RouteWithSidebar
            exact
            path={Routes.ProductTypes.path}
            component={ProductTypes}
          />
        )}
        {(admin || partner) && (
          <RouteWithSidebar
            exact
            path={Routes.Products.path}
            component={Products}
          />
        )}
        {(admin || partner) && (
          <RouteWithSidebar
            exact
            path={Routes.brands.path}
            component={Brands}
          />
        )}
        {admin && (
          <RouteWithSidebar exact path={Routes.Plans.path} component={Plans} />
        )}
        {(admin || partner) && (
          <RouteWithSidebar exact path={Routes.Deals.path} component={Deals} />
        )}
        {partner && (
          <RouteWithSidebar
            exact
            path={Routes.billingList.path}
            component={BillingList}
          />
        )}

        {admin && (
          <RouteWithSidebar
            exact
            path={Routes.advertisementLeads.path}
            component={DispAdvertisementLeads}
          />
        )}
        {(admin || partner) && (
          <RouteWithSidebar
            exact
            path={Routes.advertisement.path}
            component={DispAdvertisement}
          />
        )}
        {admin && (
          <RouteWithSidebar
            exact
            path={Routes.claimedLead.path}
            component={ClaimedLeads}
          />
        )}
        <RouteWithSidebar
          exact
          path={Routes.Coupons.path}
          component={Coupons}
        />
        {admin && (
          <RouteWithSidebar
            exact
            path={Routes.BlogTypes.path}
            component={BlogTypes}
          />
        )}
        {admin && (
          <RouteWithSidebar
            exact
            path={Routes.omcLeads.path}
            component={OmcLeads}
          />
        )}
        <RouteWithSidebar
          exact
          path={Routes.panelBlogs.path}
          component={Blogs}
        />
        <RouteWithSidebar
          exact
          path={Routes.Reviews.path}
          component={Reviews}
        />
        {admin && (
          <RouteWithSidebar
            exact
            path={Routes.blogComments.path}
            component={BlogComments}
          />
        )}
        {admin && (
          <RouteWithSidebar exact path={Routes.faq.path} component={Faq} />
        )}
        {admin && (
          <RouteWithSidebar
            exact
            path={Routes.emailMarketing.path}
            component={EmailMarketing}
          />
        )}
        {admin && (
          <RouteWithSidebar exact path={Routes.Users.path} component={Users} />
        )}
        {(admin || partner) && (
          <RouteWithSidebar
            exact
            path={Routes.Orders.path}
            component={Orders}
          />
        )}
        {roleId ? (
          <Redirect to={Routes.NotFound.path} />
        ) : (
          <Redirect to={Routes.Signin.path} />
        )}
      </Switch>
    </Suspense>
  );
};

export default App;
