import React, { useCallback, useState } from "react";
import AsyncSelect from "react-select/async";
import makeAnimated from "react-select/animated";
import axios from "../../axios";
import { useHistory } from "react-router-dom";
import { Routes } from "../../routes";
import debounce from "lodash.debounce";
import { faCannabis, faStore } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AsyncSearchBar = ({ lat, long, state, setExpanded }) => {
  const [query, setQuery] = useState(""); // To manage input query
  const [selectedOption, setSelectedOption] = useState(null); // To clear selected value
  const history = useHistory();
  const animatedComponents = makeAnimated();

  const fetchOptions = async (inputValue) => {
    try {
      const { data } = await axios.get(
        `/search/dis/with/product?lat=${lat}&long=${long}&state=${state}&name=${inputValue}`
      );

      return (
        data.data?.map((item) => ({
          value: item.id,
          label: item.name,
          type: item.type,
          icon: item.type.includes("dispensary") ? faStore : faCannabis,
          disp_id: item?.dis_id,
        })) || []
      );
    } catch (error) {
      console.error("Error fetching options:", error);
      return [];
    }
  };

  const debouncedFetchOptions = useCallback(
    debounce((inputValue, callback) => {
      fetchOptions(inputValue).then(callback);
    }, 500),
    [lat, long, state]
  );

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#65ac4b" : "#9fa1a2",
      boxShadow: state.isFocused ? "0 0 0 1px #65ac4b" : "0 0 0 1px #9fa1a2",
      "&:hover": {
        borderColor: "#65ac4b",
        boxShadow: "0 0 0 1px #65ac4b",
      },
    }),
  };

  const handleSelectChange = (selectedOption) => {
    if (!selectedOption) return;
    const { label, value, type, disp_id } = selectedOption;
    const name = label.split("(")[0];

    const path = type.includes("dispensary")
      ? `${Routes.dispensaryDetails.path}/${value}/${name}/profile`
      : `${Routes.productDetails.path}/${name.replaceAll(
          " ",
          "-"
        )}/${value}/${disp_id}`;
    setExpanded(false);
    history.push({ pathname: path });

    // Clear both the selected value and query after selection
    setSelectedOption(null);
    setQuery(""); // Reset the input field to show placeholder
  };

  const formatOptionLabel = ({ label, icon }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <FontAwesomeIcon icon={icon} style={{ marginRight: 10 }} />
      {label}
    </div>
  );

  return (
    <div>
      <AsyncSelect
        cacheOptions
        isClearable
        components={animatedComponents}
        loadOptions={debouncedFetchOptions}
        styles={customStyles}
        inputValue={query} // Controlled input value
        value={selectedOption} // Ensure selected value is cleared
        onInputChange={(value) => setQuery(value)}
        onChange={handleSelectChange}
        placeholder="Search Products & Dispensaries" // Placeholder when input is cleared
        formatOptionLabel={formatOptionLabel}
      />
    </div>
  );
};

export default AsyncSearchBar;
