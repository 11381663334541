import { Card, Row, Table } from "react-bootstrap";
import moment from "moment";
import CustomPagination from "../Pagination";

const DispAdvertisementLeadsTable = ({
  disLeadsData,
  tableContainerRef,
  currentPage,
  setCurrentPage,
  totalPages,
}) => {
  return (
    <>
      <Card
        border="light"
        className="table-wrapper table-responsive shadow-sm "
      >
        <Card.Body className="p-0 pb-4 justify-content-center">
          <div ref={tableContainerRef} className="table-responsive">
            {disLeadsData?.length > 0 ? (
              <Table hover className="user-table min-height">
                <thead>
                  <tr>
                    <th className="border-bottom">ID</th>
                    <th className="border-bottom">Name</th>
                    <th className="border-bottom">Email</th>
                    <th className="border-bottom">Phone Number</th>
                    <th className="border-bottom">Zip code</th>
                    <th className="border-bottom">Created At</th>
                  </tr>
                </thead>
                <tbody>
                  {disLeadsData.map((lead) => (
                    <tr key={lead.id}>
                      <td text-label="ID">
                        <span className="fw-normal">{lead.id}</span>
                      </td>

                      <td text-label="Name">
                        <span className="fw-normal">{lead.name}</span>
                      </td>
                      <td text-label="Email">
                        <span className="fw-normal">{lead.email}</span>
                      </td>
                      <td text-label="Phone Number">
                        <span className="fw-normal">{lead.phone_no}</span>
                      </td>
                      <td text-label="Phone Number">
                        <span className="fw-normal">
                          {lead.zipcode || "N/A"}
                        </span>
                      </td>

                      <td text-label="Created At">
                        <span className="fw-normal">
                          {moment(lead.created_at).format("DD MMM YYYY")}
                        </span>
                      </td>

                      {/* <td>
                        <Dropdown as={ButtonGroup} drop="down-centered">
                          <Dropdown.Toggle
                            as={Button}
                            split
                            variant="link"
                            className="text-dark m-0 p-0"
                          >
                            <span className="icon icon-sm">
                              <FontAwesomeIcon
                                icon={faEllipsisH}
                                className="icon-dark"
                              />
                            </span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => {
                                setShowModal(true);
                                setDealId(deal.id);
                                setDealData({
                                  name: deal.name,
                                  description: deal.description,
                                  discount: deal.discount,
                                  start_date: deal.start_date,
                                  end_date: deal.end_date,
                                  dis_id: deal.dispensary.id,
                                  coupon_id: deal.coupon?.id,
                                  products: deal.products,
                                  status: deal.status,
                                  is_advertise: deal.is_advertise,
                                  image: deal.image,
                                });
                              }}
                            >
                              <FontAwesomeIcon icon={faEdit} className="me-2" />{" "}
                              Edit
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="text-danger"
                              onClick={() => deleteHandler(deal.id)}
                            >
                              <FontAwesomeIcon
                                icon={faTrashAlt}
                                className="me-2"
                              />{" "}
                              Remove
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <Row className="justify-content-center align-item-center text-dark fw-bold p-4">
                {" "}
                No Data Available.....
              </Row>
            )}
          </div>
        </Card.Body>
        {totalPages > 0 ? (
          <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-end">
            <CustomPagination
              totalPages={totalPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </Card.Footer>
        ) : (
          ""
        )}
      </Card>
    </>
  );
};
export default DispAdvertisementLeadsTable;
