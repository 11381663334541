// libraries
import { useQuery } from "@tanstack/react-query";
import { Col, Row, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Components
import axios from "../../axios";
import { loadingSet } from "../../redux/loadingSlice";
import ServerError from "../Login/ServerError";
import LoadingPlaceHolder from "../../reusable/Helper/LoadingPlaceHolder";
import BlogCommentsTable from "../../components/Table/BlogCommentsTable";

const BlogComments = () => {
  // states
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  // dispatch
  const dispatch = useDispatch();

  // Functions
  const getBlogComments = async ({ queryKey }) => {
    if (queryKey) {
      const [, page] = queryKey;
      const res = await axios(`/blog/comments?page=${page}&limit=20`);
      return res.data.data;
    }
  };

  const {
    data,
    isLoading: blogCommentsLoading,
    error: blogCommentsError,
    refetch: refetchBlogComments,
  } = useQuery({
    queryKey: ["blog-commnets-list", currentPage],
    queryFn: getBlogComments,
  });

  // useEffects
  useEffect(() => {
    dispatch(loadingSet(blogCommentsLoading));
  }, [blogCommentsLoading, dispatch]);

  if (blogCommentsError) {
    return (
      <ServerError
        errorMessage={
          blogCommentsError?.response?.data?.message || blogCommentsError
        }
      />
    );
  }

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h3>Blog Comments</h3>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0"></div>
      </div>

      {/* <div className="table-settings mb-4">
        <Row className="justify-content-end align-items-center ">
          <Col xl={2} md={4} lg={3} xs={6}>
            <Row className="justify-content-end me-2">
              <Button
                onClick={() => setShowModal(true)}
                className="green-btn fontweigh-500"
              >
                Add Blog Comments
              </Button>
            </Row>
          </Col>
        </Row>
      </div> */}
      {/* {showModal && (
        <AddEditReviews
          show={showModal}
          handleClose={() => setShowModal(false)}
          refetchReviews={refetchReviews}
        />
      )} */}
      {blogCommentsLoading ? (
        <LoadingPlaceHolder />
      ) : (
        <BlogCommentsTable
          blogComments={data?.blogComments}
          totalPages={data?.totalPages}
          refetchBlogComments={refetchBlogComments}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </>
  );
};

export default BlogComments;
