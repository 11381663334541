import { Button, Card, Image, Row, Table } from "react-bootstrap";
import CustomPagination from "../Pagination";

const EmailMarketingTable = ({
  EmailSentData,
  currentPage,
  setCurrentPage,
  totalPages,
  refetchEmailSentData,
}) => {
  return (
    <>
      <Card
        border="light"
        className="table-wrapper table-responsive shadow-sm "
      >
        <Card.Body className="pt-0 pb-4 px-0 justify-content-center">
          {EmailSentData.length > 0 ? (
            <Table responsive hover className="user-table min-height">
              <thead>
                <tr>
                  <th className="border-bottom">S.No</th>
                  <th className="border-bottom">Dispensary ID</th>
                  <th className="border-bottom">Cover Image</th>
                  <th className="border-bottom">Logo</th>

                  <th className="border-bottom">Dispensary Name</th>
                  <th className="border-bottom">Contact</th>
                  <th className="border-bottom">Link Clicked</th>
                  <th className="border-bottom">Claimed Status</th>
                </tr>
              </thead>
              <tbody>
                {EmailSentData.map((data, index) => (
                  <tr key={data.id}>
                    <td text-label="ID">
                      <span className="fw-normal">{index + 1}</span>
                    </td>
                    <td text-label="Dispensary ID">
                      <span className="fw-normal">{data.id}</span>
                    </td>
                    <td text-label="Cover Image">
                      <td text-label="Cover Image">
                        <Image
                          src={data.cover_image}
                          alt="dispensary cover"
                          className="fw-normal"
                        />
                      </td>
                    </td>
                    <td text-label="Logo">
                      {data.logo ? (
                        <Image
                          src={data.logo}
                          alt="dispensary logo"
                          className="fw-normal"
                        />
                      ) : (
                        "N/A"
                      )}
                    </td>
                    <td text-label="Dispensary Name">
                      <span className="fw-normal">{data.name}</span>
                    </td>
                    <td text-label="Contact">
                      <p className="fw-normal">Email: {data.contact_email} </p>
                      <p className="fw-normal">Phone: {data.phone_number} </p>
                    </td>
                    <td text-label="Link Clicked">
                      <span className="fw-normal">
                        {data.link_clicked_count ? "Clicked" : "Not Clicked"}
                      </span>
                    </td>
                    <td text-label="Link Clicked" className="fw-normal">
                      {data.claim === 0
                        ? "Pending"
                        : data.claim === 1
                        ? "Claimed"
                        : data.claim === 2
                        ? "Cancelled"
                        : "Not Claimed"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <Row className="justify-content-center align-item-center text-dark fw-bold p-4">
              {" "}
              No Data Available.....
            </Row>
          )}
        </Card.Body>
        {totalPages ? (
          <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-end">
            <CustomPagination
              totalPages={totalPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </Card.Footer>
        ) : (
          ""
        )}
      </Card>
    </>
  );
};
export default EmailMarketingTable;
