import {
  Button,
  ButtonGroup,
  Card,
  Dropdown,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import CustomToast from "../../reusable/CustomToast";
import { useEffect, useState } from "react";

//axios
import axios from "../../axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEllipsisH } from "@fortawesome/free-solid-svg-icons";

import { useDispatch, useSelector } from "react-redux";
import { loadingSet } from "../../redux/loadingSlice";

import CustomPagination from "../Pagination";
import moment from "moment";

const BlogCommentsTable = ({
  blogComments,
  refetchBlogComments,
  totalPages,
  currentPage,
  setCurrentPage,
}) => {
  //States
  //customtoast states
  const [showToast, setShowToast] = useState(false);
  const [toastBody, setToastBody] = useState("");
  const [success, setSuccess] = useState(true);

  //loading
  const [loading, setLoading] = useState(false);

  //dispatch
  const dispatch = useDispatch();
  const { role, plan_id } = useSelector((state) => state.login.userData);
  //useEffects
  useEffect(() => {
    dispatch(loadingSet(loading));
  }, [loading]);

  //Functions
  //   const deleteHandler = async (id) => {
  //     setLoading(true);
  //     try {
  //       const res = await axios({
  //         method: "delete",
  //         url: `/dis/review/delete/${id}`,
  //       });

  //       setShowToast(true);
  //       setToastBody(res.data.message);
  //       setSuccess(true);
  //       refetchReviews();
  //     } catch (err) {
  //       setShowToast(true);
  //       setToastBody(err.response.data.message);
  //       setSuccess(false);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };
  const statusHandler = async (id, currentStatus) => {
    try {
      const res = await axios({
        method: "post",
        url: `/blog/comment/update/${id}`,
        data: { status: currentStatus === 1 ? 0 : 1 },
      });

      setShowToast(true);
      setToastBody(res.data.message);
      setSuccess(true);
      refetchBlogComments();
    } catch (err) {
      console.log(err, "erorr hai");
      setShowToast(true);
      setToastBody(err.response.data.message);
      setSuccess(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Card
        border="light"
        className="table-wrapper table-responsive shadow-sm "
      >
        <Card.Body className="p-0 pb-4 justify-content-center">
          {blogComments?.length > 0 ? (
            <Table responsive hover className="user-table min-height">
              <thead>
                <tr>
                  <th className="border-bottom">ID</th>

                  <th className="border-bottom">Comment</th>
                  <th className="border-bottom">Commneted By</th>
                  <th className="border-bottom">Status</th>
                  <th className="border-bottom">Commented Date</th>
                  <th className="border-bottom">Action</th>
                </tr>
              </thead>
              <tbody>
                {blogComments.map((comment) => (
                  <tr key={comment.id}>
                    <td text-label="ID">
                      <span className="fw-normal">{comment.id}</span>
                    </td>

                    <td text-label="Comment">
                      <span className="fw-normal">{comment.comment}</span>
                    </td>
                    <td text-label="Commented By">
                      <span className="fw-normal">{comment.user.name}</span>
                    </td>

                    <td text-label="Status">
                      <span className="fw-normal">
                        {comment.status ? "Yes" : "No"}
                      </span>
                    </td>
                    <td text-label="Commented Date">
                      <span className="fw-normal">
                        {moment(comment.updated_at).format("DD MMM YYYY")}
                      </span>
                    </td>
                    <td>
                      <td>
                        <Dropdown as={ButtonGroup} drop="down-centered">
                          <Dropdown.Toggle
                            as={Button}
                            split
                            variant="link"
                            className="text-dark m-0 p-0"
                            disabled={role === "partner" && plan_id === 1}
                          >
                            <span className="icon icon-sm">
                              <FontAwesomeIcon
                                icon={faEllipsisH}
                                className="icon-dark"
                              />
                            </span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() =>
                                statusHandler(comment.id, comment.status)
                              }
                            >
                              <FontAwesomeIcon
                                icon={faEdit}
                                className="icon-dark"
                              />{" "}
                              {comment.status === 1
                                ? "Mark status inactive"
                                : "Mark status active"}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <Row className="justify-content-center align-item-center text-dark fw-bold p-4">
              {" "}
              No Data Available.....
            </Row>
          )}
        </Card.Body>
        {totalPages > 0 ? (
          <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-end">
            <CustomPagination
              totalPages={totalPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </Card.Footer>
        ) : (
          ""
        )}
      </Card>

      <CustomToast
        show={showToast}
        toastBody={toastBody}
        setShow={setShowToast}
        success={success}
      />
    </>
  );
};
export default BlogCommentsTable;
