export const Routes = {
  // pages

  //WEBSITE
  Home: { path: "/" },
  termsOfUSe: {
    path: "/terms-of-use",
  },
  customerTerms: { path: "/terms-of-use/customer-terms-of-use" },
  businessTerms: { path: "/terms-of-use/business-terms-of-use" },
  supplementalAds: { path: "/terms-of-use/supplemental-ads-terms" },
  supplementalProduct: { path: "/terms-of-use/supplemental-product-terms" },
  acceptableUse: { path: "/terms-of-use/acceptable-use-policy" },
  deliveryDataProcessingAddendum: {
    path: "/terms-of-use/delivery-data-processing-addendum",
  },
  electronicCommPolicy: {
    path: "/terms-of-use/electronic-communications-policy",
  },
  ordersDataProcessingAddendum: {
    path: "/terms-of-use/orders-data-processing-addendum",
  },
  PrivacyPolicy: { path: "/privacy-policy" },
  Explore: { path: "/explore" },
  NearbyDeals: { path: "/nearby-deals" },
  allBrands: { path: "/all-brands" },
  // AllProducts: { path: "/all-products" },
  blogs: { path: "/blogs" },
  ProductsByCategory: { path: "/products-by-category" },
  ProductsByBrand: { path: "/products-by-brands" },
  dispensaryDetails: { path: "/dispensary-details" },
  productDetails: { path: "/product-details" },
  dealProducts: { path: "/deal-products" },
  blogDetails: { path: "/blog-details" },
  cart: { path: "/cart" },
  myOrders: { path: "/my-orders" },
  buyPlan: { path: "/buy-plan" },
  planPayment: { path: "/buy-plan/payment" },
  faqPage: { path: "/FAQ" },

  // Admin Dashboard

  AdminDashboard: {
    path: "/panel",
    redirectTo: "/panel/dashboard/overview",
  },
  States: { path: "/panel/states" },
  DispensaryTypes: { path: "/panel/dispensary-types" },
  Dispensaries: { path: "/panel/dispensaries" },
  ProductTypes: { path: "/panel/product-types" },
  Products: { path: "/panel/products" },
  brands: { path: "/panel/brands" },
  Plans: { path: "/panel/plans" },
  Coupons: { path: "/panel/coupons" },
  advertisementLeads: { path: "/panel/advertise-leads" },
  advertisement: { path: "/panel/advertise" },
  omcLeads: { path: "/panel/omc-leads" },
  claimedLead: { path: "/panel/claimed-lead" },
  Deals: { path: "/panel/deals" },
  billingList: { path: "/panel/billing-list" },
  panelBlogs: { path: "/panel/blogs" },
  BlogTypes: { path: "/panel/blog-types" },
  Reviews: { path: "/panel/reviews" },
  blogComments: { path: "/panel/blog-comments" },
  emailMarketing: { path: "/panel/email-marketing" },
  Users: { path: "/panel/users" },
  Orders: { path: "/panel/orders" },
  faq: { path: "/panel/FAQ" },

  //common Routes
  userCheckout: { path: "/checkout" },
  MyProfile: { path: "/my-profile" },
  BootstrapTables: { path: "/tables/bootstrap-tables" },
  Billing: { path: "/examples/billing" },
  Invoice: { path: "/examples/invoice" },
  Signin: { path: "/sign-in" },
  Signup: { path: "/sign-up" },
  ForgotPassword: { path: "/forgot-password" },
  ResetPassword: { path: "/reset-password" },
  ChangePassword: { path: "/change-password" },
  NotFound: { path: "/not-found" },
  ServerError: { path: "/server-error/500" },
  allNotification: { path: "/all-notifications" },

  // components
  Accordions: { path: "/components/accordions" },
  Alerts: { path: "/components/alerts" },
  Badges: { path: "/components/badges" },
  Widgets: { path: "/widgets" },
  Breadcrumbs: { path: "/components/breadcrumbs" },
  Buttons: { path: "/components/buttons" },
  Forms: { path: "/components/forms" },
  Modals: { path: "/components/modals" },
  Navs: { path: "/components/navs" },
  Navbars: { path: "/components/navbars" },
  Pagination: { path: "/components/pagination" },
  Popovers: { path: "/components/popovers" },
  Progress: { path: "/components/progress" },
  Tables: { path: "/components/tables" },
  Tabs: { path: "/components/tabs" },
  Tooltips: { path: "/components/tooltips" },
  Toasts: { path: "/components/toasts" },
  WidgetsComponent: { path: "/components/widgets" },
};
